import React from "react"
import { PageProps } from "gatsby"

import Layout from "../components/Layout"
import CTA from "../components/CTA"
import SEO from "../components/SEO"
import WipeScreen from "../components/WipeScreen"

import { Container, Row, Col } from "reactstrap"

type DataProps = {
  site: {
    buildTime: string
    siteMetadata: {
      title: string
    }
  }
}

const Careers = () => {
  return (
    <>
      <SEO title={"Careers"} slug={"careers"} />
      <Row className="white-container case-study">
        <Container className="mt-5 pt-5">
          <Row className="mt-5 pt-5 no-gutters">
            <Col sm="8" className="super-margin">
              <h1>Join our team and build the future of the digital world.</h1>
              <br />
              <p>Careers</p>
            </Col>
          </Row>
        </Container>
      </Row>
      <Row className="yellow-container">
        <Container>
          <Row className="mt-5 no-gutters">
            <Col sm="8" className="super-margin">
              <h1>Join our team and build the future of the digital world.</h1>
            </Col>
            <Row className="no-gutters mb-5">
              <Col sm="4"></Col>
              <Col sm="8" className="pr-5 mb-5">
                <p>
                  Simple problems need effective solutions. Complex problems
                  need simplification
                </p>
                <p>
                  ...that are looking for disruptive solutions, trying to
                  challenge the status quo, and search for fresh, creative and
                  bold approach. I am not afraid of any time zone, you should be
                  neither.
                </p>
              </Col>
            </Row>
          </Row>
          <Row className="no-gutters mt-5 mb-5">
            <Col md="4">
              <h5>Open positions</h5>
            </Col>
            <Col md="8" className="long">
              <div className="pb-3">
                <h3>no-code / low-code developer</h3>
                <p>Description here</p>
                <span>
                  Build websites with tools like:
                  https://mailchimp.com/features/website-builder/ We do
                  integrations and automations with no-code 3rd party tools
                  like...
                </span>
              </div>
              <hr />
              <div className="pt-3 pb-3">
                <h3>Junior Product Owner</h3>
                <p>Description here</p>
                <span>Make it toggable?</span>
              </div>
              <hr />
              <div className="pt-3 pb-3 mb-5">
                <h3>UI/UX designer</h3>
                <p>Description here</p>
              </div>
            </Col>
          </Row>
        </Container>
      </Row>
      <CTA />
      <WipeScreen />
    </>
  )
}

Careers.Layout = Layout
export default Careers
